#drift-widget-container { z-index: 1 !important;}
@font-face {
  font-family: -apple-system, system-ui, sans-serif;
  color: #25333d;
  line-height: 140%;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 140%;
  background-color: #fff;
  z-index: 0;
  color: #25333d;
  font-family: -apple-system, system-ui, sans-serif;
}

h1 {
  line-height: 120%;
}

a {
  color: #25333d;
}

input[type="text"] {
  font-size: inherit;
}

label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}

button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  border: 1px solid #5BC0EB;
  background-color: #5BC0EB;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #78CBEE;
  border: 1px solid #78CBEE;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
}

.buttonOffWhite {
  color: #5BC0EB;
  background-color: #fff;
  border: 1px solid #5BC0EB;
}

.buttonOffWhite:hover {
  color: #78CBEE;
  background-color: #fff;
  border: 1px solid #78CBEE;
}

input, select, textarea,
.StripeElement {
  display: block;
  flex: 1;
  margin: 5px 10px;
  padding: 10px 14px;
  font-size: 1em;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  background: white;
}

textarea {
  min-height: 7rem;
}

input::placeholder, textarea::placeholder {
  color: #aab7c4;
  font-weight: 300;
  line-height: 140%;
}

input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.inputError {
  border-color: #bb0000;
}

.inputError::placeholder {
  color: #bb0000;
}

.verifyContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  min-height: 70vh;
}

.verifySection {
  margin: 1rem 0;
}

.verifyTitle {
  font-family: -apple-system, system-ui, sans-serif;
  margin: 0;
  font-size: 2rem;
}

.verifyHeader {
  font-family: -apple-system, system-ui, sans-serif;
  margin: 0 0 1rem;
  font-size: 1.5rem;
}

.verifyText {
  font-family: -apple-system, system-ui, sans-serif;
  margin: 0;
  font-size: 1rem;
}

.datePicker {
  display: flex;
  flex: none;
  border: none;
  box-shadow: none;
  width: 100%;
  padding: 0;
  cursor: pointer;
  margin: 0;
  outline: none;
  color: #25333d;
  font-weight: 600;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.defaultText {
  font-weight: 300;
  font-size: 1rem;
  line-height: 140%;
}

.defaultBoldText {
  font-weight: 700;
  font-size: 1rem;
  line-height: 140%;
}

.defaultSmallText {
  font-weight: 200;
  font-size: 0.9rem;
  line-height: 140%;
}

.defaultHeaderText {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 140%;
  margin: 0 0 0.5rem 0;
}

.defaultHeaderTextColor {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 140%;
  margin: 0 0 0.5rem 0;
  color: #5BC0EB;
}

.defaultSubheaderText {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 140%;
  margin: 0 0 1rem 0;
}

.defaultSubtitleText {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 140%;
  margin: 0 0 1rem 0;
}

.defaultErrorText {
  font-weight: 300;
  line-height: 140%;
  color: #bb0000;
}

.defaultSuccessText {
  font-weight: 300;
  line-height: 140%;
  color: #5BC0EB;
}

.defaultPromptText {
  cursor: pointer;
  margin-top: 1rem;
  text-decoration: underline
}

.defaultContainer {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.defaultRowContainer {
  display: flex;
  flex-direction: row;
  margin: 1rem;
}

.defaultReceiptContainer {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ebebeb;
  padding: 1rem 0;
}

.receiptLineItemRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.receiptPaymentTypeContainer {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ebebeb;
  margin: 0.5rem 0 0;
  padding: 0.5rem 0 0;
}

.receiptPaymentLogoRow {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 0;
  justify-content: center;
}

.receiptPaymentLogo {
  max-height: 2.3rem;
  margin: 0 0.5rem;
}

.priceTimingText {
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 140%;
  text-align: left;
  margin: 0 0 0.4rem;
}

.priceLineText {
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 140%;
  text-align: left;
  margin: 0.75rem 0 0.25rem;
}

.discountPriceLineText {
  font-weight: 300;
  font-size: 0.9rem;
  color: #00A659;
  line-height: 140%;
  text-align: left;
  margin: 0.75rem 0 0.25rem;
}

.finalPriceLineText {
  font-weight: 700;
  font-size: 0.9rem;
  line-height: 140%;
  text-align: left;
  margin: 0.5rem 0;
}

.defaultNavLink {
  text-decoration: none;
  margin: 0.5rem auto 0 0;
  padding: 0;
  color: #fff;
}

.navButtonWrapper {
  display: flex;
  margin: 0 auto;
  text-align: center;
  flex-direction: column;
}

.centerNavLink {
  text-decoration: none;
  padding: 0;
  color: #fff;
}

.pagePartnerContainerRow {
  display: flex;
  flex-direction: row;
  flex: 2;
  margin: 1rem;
  padding: 2rem 1rem;
  border: 1px solid #ebebeb;
  background-color: #fff;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  margin: 6rem 3rem;
  padding: 1rem;
  border: 1px solid #ebebeb;
}

.pagePartnerImage {
  width: 10rem;
  object-fit: contain;
}

.appContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.pageAssetContainer {
  flex: 1;
  padding: 0 1rem;
  max-height: 12rem;
  border: 0;
  object-fit: cover;
  overflow: hidden;
}

.basicIcon {
  height: 1.25rem;
  width: 1.25rem;
}

.modalButton {
  margin: 0 0.5rem;
}

@media screen and (max-width: 850px){
  .paddedContainer {
    padding: 5rem 1.25rem;
    align-items: center;
    justify-content: center;
  }
  .verifyContainer {
    margin: 0 1rem;
  }
  .pageAssetContainer {
    padding: 0.5rem 1rem;
    overflow: visible;
  }
  .pagePartnerContainerRow {
    flex-direction: column;
    align-items: center;
  }
  .reverseSplitContainer {
    display: flex;
    flex-direction: column-reverse;
  }
  .splitContainer {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 850px) and (max-width: 1095px){
  .paddedContainer {
    padding: 5rem 2rem;
  }
  .pagePartnerContainerRow {
    flex-direction: row;
  }
  .reverseSplitContainer {
    display: flex;
    flex-direction: row;
  }
  .splitContainer {
    display: flex;
    flex-direction: row;
  }
  .pageAssetContainer {
    padding: 0 1rem;
    overflow: hidden;
  }
  .pagePartnerImage {
    height: 100%;
  }
}
@media screen and (min-width: 1095px){
  .paddedContainer {
    padding: 5rem calc(1/7*100%);
  }
  .pagePartnerContainerRow {
    flex-direction: row;
  }
  .reverseSplitContainer {
    display: flex;
    flex-direction: row;
  }
  .splitContainer {
    display: flex;
    flex-direction: row;
  }
  .pageAssetContainer {
    max-height: 20rem;
  }
  .pagePartnerImage {
    height: 100%;
  }
}