.noticeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 6rem;
}
.notice {
  margin: 0 0 1rem;
}
.noticeSubtitle {
  margin: 0 0 1rem;
  font-weight: 300;
}
.noticeImage {
  height: auto;
  width: 450px;
}
@media screen and (max-width: 950px){
  .noticeContainer {
    margin: 2rem
  }
  .noticeImage {
    max-width: 90%;
  }
}